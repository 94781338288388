import type { TFunction } from 'i18next';
import { LocationReference } from './location';
import { Sensor } from './sensor';

export enum ConnectionType {
  CHARP = 'charp',
  ADDON = 'addon',
  STANDALONE = 'standalone',
}

export const connectionTypes: { [key in ConnectionType]?: string } = {
  [ConnectionType.CHARP]: 'CHARP',
  [ConnectionType.STANDALONE]: 'Standalone',
};

export enum CharpTypes {
  DEVICE = 'device',
  GATEWAY = 'gateway',
}

export enum CharpDeviceTypes {
  XTEL = 'xtel',
  ELSYS = 'elsys',
  TALKPOOL = 'talkpool',
  WATTECO = 'watteco',
  ABEEWAY = 'abeeway',
}

export enum CharpGatewayTypes {
  XTEL = 'xtel',
  COMPULAB = 'compulab',
  KERLINK = 'kerlink',
}

export const charpDeviceTypes: { [key in CharpDeviceTypes]: string } = {
  [CharpDeviceTypes.XTEL]: 'Xtel',
  [CharpDeviceTypes.ELSYS]: 'Elsys',
  [CharpDeviceTypes.TALKPOOL]: 'Talkpool',
  [CharpDeviceTypes.WATTECO]: 'Watteco',
  [CharpDeviceTypes.ABEEWAY]: 'Abeeway',
};

export const charpGatewayTypes: { [key in CharpGatewayTypes]: string } = {
  [CharpGatewayTypes.XTEL]: 'Xtel',
  [CharpGatewayTypes.KERLINK]: 'Kerlink',
  [CharpGatewayTypes.COMPULAB]: 'Compulab',
};

export enum AddonDeviceTypes {
  VERSASENSE = 'versasense',
}

export const addonDeviceTypes: { [key in AddonDeviceTypes]: string } = {
  [AddonDeviceTypes.VERSASENSE]: 'VersaSense',
};

export enum StandaloneDeviceTypes {
  ELSEC = 'elsec',
  HANWELL = 'hanwell',
  LASCAR_ELECTRONICS = 'lascar_electronics',
  OTHER = 'other',
}

export const standaloneDeviceTypes: { [key in StandaloneDeviceTypes]: string } = {
  [StandaloneDeviceTypes.ELSEC]: 'Elsec',
  [StandaloneDeviceTypes.HANWELL]: 'Hanwell',
  [StandaloneDeviceTypes.LASCAR_ELECTRONICS]: 'Lascar Electronics',
  [StandaloneDeviceTypes.OTHER]: 'Other',
};

export enum ElsecDeviceModels {
  ELSEC_765 = '765',
}

export const elsecDeviceModels: { [key in ElsecDeviceModels]: string } = {
  [ElsecDeviceModels.ELSEC_765]: '765',
};

export enum ElsysDeviceModels {
  ERS = 'ERS',
}

export const elsysDeviceModels: { [key in ElsysDeviceModels]: string } = {
  [ElsysDeviceModels.ERS]: 'ERS',
};

export enum HanwellDeviceModels {
  RL45 = 'rl45',
}

export const hanwellDeviceModels: { [key in HanwellDeviceModels]: string } = {
  [HanwellDeviceModels.RL45]: 'RL45xx',
};

export enum LascarElectronicsDeviceModels {
  EASYLOG_EL_USB_2_PLUS = 'easylog_el-usb-2+',
  EASYLOG_EL_USB_TC = 'easylog_el-usb-tc',
}

export const lascarElectronicsDeviceModels: { [key in LascarElectronicsDeviceModels]: string } = {
  [LascarElectronicsDeviceModels.EASYLOG_EL_USB_2_PLUS]: 'EasyLog EL-USB-2+',
  [LascarElectronicsDeviceModels.EASYLOG_EL_USB_TC]: 'EasyLog EL-USB-TC',
};

export type DeviceType = CharpDeviceTypes | AddonDeviceTypes | StandaloneDeviceTypes | CharpGatewayTypes;
export type DeviceModel =
  | ElsecDeviceModels
  | ElsysDeviceModels
  | HanwellDeviceModels
  | LascarElectronicsDeviceModels
  | string;

export interface Device {
  id: string;
  name: string;
  connection: ConnectionType;
  type: DeviceType;
  model?: DeviceModel;
  locations: LocationReference[];
  battery?: number;
  sensors?: Sensor[];
  updatedAt: string;
  createdAt: string;
}

export function validateName(name: string, t: TFunction): string | undefined {
  if (name.length < 1) {
    return t('A device needs a name');
  } else if (name.length > 255) {
    return t('Device name cannot exceed 255 characters');
  }
}

export function validateLocation(location: string | null, t: TFunction): string | undefined {
  if (location !== null) {
    if (location.length < 1) {
      return t('A device location should have a name');
    } else if (location.length > 255) {
      return t('A device location cannot exceed 255 characters');
    }
  }
}
