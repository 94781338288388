import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';

const DatePickerGlobalStyles: FC = () => {
  const Style = createGlobalStyle`
    .rdp-caption_label {
        display: none;
    }
    .rdp-day {
        text-align: center;
    }
    .rdp-month {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
    .rdp-nav {
        position: absolute;
    }
    .rdp-dropdowns {
        display: flex;
        justify-content: end;
        .rdp-dropdown {
            color: black;
            font-size: 1.25rem; 
            font-weight: 600;
            font-family: inherit;
            text-align: center;
        }
    }
    `;
  return <Style />;
};

export default DatePickerGlobalStyles;
